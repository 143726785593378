import { h } from "preact";

export const NoAudioIcon = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M14.8291 12.0006L9 6.17157V6C9 4.34315 10.3431 3 12 3C13.6569 3 15 4.34315 15 6V11C15 11.3509 14.9398 11.6877 14.8291 12.0006Z"
                fill="#999999"
            />
            <path
                d="M5.70711 4.29289C5.31658 3.90237 4.68342 3.90237 4.29289 4.29289C3.90237 4.68342 3.90237 5.31658 4.29289 5.70711L9 10.4142V11C9 12.6569 10.3431 14 12 14C12.1836 14 12.3633 13.9835 12.5377 13.9519L14.1169 15.5311C13.474 15.8319 12.7566 16 12 16C9.23858 16 7 13.7614 7 11V10C7 9.44771 6.55228 9 6 9C5.44772 9 5 9.44771 5 10V11C5 14.5273 7.60896 17.4453 11.0024 17.9295C11.0008 17.9528 11 17.9763 11 18V20C11 20.5523 11.4477 21 12 21C12.5523 21 13 20.5523 13 20V18C13 17.9763 12.9992 17.9528 12.9976 17.9295C13.9359 17.7956 14.8142 17.4756 15.594 17.0082L18.2929 19.7071C18.6834 20.0976 19.3166 20.0976 19.7071 19.7071C20.0976 19.3166 20.0976 18.6834 19.7071 18.2929L5.70711 4.29289Z"
                fill="#999999"
            />
            <path
                d="M19 11C19 12.4658 18.5495 13.8264 17.7793 14.9509L16.3301 13.5017C16.7562 12.7659 17 11.9114 17 11V10C17 9.44771 17.4477 9 18 9C18.5523 9 19 9.44771 19 10V11Z"
                fill="#999999"
            />
        </svg>
    );
};
