exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "button.style__timer-button___3FQGj {\n  cursor: pointer;\n  background-color: transparent;\n  border: transparent;\n  padding: 0; }\n  button.style__timer-button___3FQGj svg {\n    vertical-align: text-top; }\n\n.style__recording-menu___3ZZaC {\n  padding: 13px 22px 13px 14px;\n  height: 30px;\n  border-radius: 28px;\n  background-color: #222222;\n  color: #ffffff;\n  font-size: 16px;\n  font-weight: 700;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  width: 82px; }\n\n.style__recording-timer___1gDMY {\n  display: inline-block; }\n\n.style__timer___10btU {\n  margin-left: 8px; }\n", ""]);

// Exports
exports.locals = {
	"timer-button": "style__timer-button___3FQGj",
	"timerButton": "style__timer-button___3FQGj",
	"recording-menu": "style__recording-menu___3ZZaC",
	"recordingMenu": "style__recording-menu___3ZZaC",
	"recording-timer": "style__recording-timer___1gDMY",
	"recordingTimer": "style__recording-timer___1gDMY",
	"timer": "style__timer___10btU"
};