exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".style__error-screen-wrap___3Isvv{height:100%;width:100%;background-color:#ffdd87;color:#8b572a;text-shadow:1px 1px 0 0 hsla(0,0%,100%,.5);margin:auto;display:flex;align-items:center}.style__error-screen-wrap___3Isvv .style__error-screen___2dXD3{text-align:center;width:100%}.style__error-screen-wrap___3Isvv .style__error-screen___2dXD3 h1.style__error-screen__title___15S7s{font-size:18px;font-weight:bold;line-height:22px}.style__error-screen-wrap___3Isvv .style__error-screen___2dXD3 p.style__error-screen__text___BcQ5s{font-size:16px;line-height:19px;width:75%;margin:auto;overflow:auto;word-wrap:break-word}.style__error-screen__reset-button___2eg4o{height:36px;width:130px;position:relative;font-size:14px;font-weight:500;outline:none;cursor:pointer;margin-top:20px}", ""]);

// Exports
exports.locals = {
	"error-screen-wrap": "style__error-screen-wrap___3Isvv",
	"errorScreenWrap": "style__error-screen-wrap___3Isvv",
	"error-screen": "style__error-screen___2dXD3",
	"errorScreen": "style__error-screen___2dXD3",
	"error-screen__title": "style__error-screen__title___15S7s",
	"errorScreenTitle": "style__error-screen__title___15S7s",
	"error-screen__text": "style__error-screen__text___BcQ5s",
	"errorScreenText": "style__error-screen__text___BcQ5s",
	"error-screen__reset-button": "style__error-screen__reset-button___2eg4o",
	"errorScreenResetButton": "style__error-screen__reset-button___2eg4o"
};