

export enum KalturaMediaType {
    audio=5,
	image=2,
	liveStreamFlash=201,
	liveStreamQuicktime=204,
	liveStreamRealMedia=203,
	liveStreamWindowsMedia=202,
	video=1
}