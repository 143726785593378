exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".style__countdown___1Ow7-{position:relative;margin:auto;margin-top:100px;height:88px;width:88px;text-align:center}@media(max-width: 256px){.style__countdown___1Ow7-{margin-top:110px}}.style__countdown-number___1vi4T{color:#fff;display:inline-block;line-height:88px;font-size:48px;z-index:2;position:relative}@media(max-width: 320px){.style__countdown-number___1vi4T{line-height:30px;font-size:25px}}@keyframes style__countdown-anim___3k8i6{from{stroke-dashoffset:0}to{stroke-dashoffset:264px}}.style__circle___QVwOK{-webkit-animation:style__spin___3k0z_ 1s linear infinite;animation:style__spin___3k0z_ 1s linear infinite;border:4px solid rgba(0,0,0,0);border-top:4px solid #fff;border-radius:50%;height:75px;width:75px;margin-top:-86px;margin-left:2px;background-color:rgba(0,0,0,.5)}@media(max-width: 320px){.style__circle___QVwOK{width:40px;height:40px;margin-left:20px;margin-top:-39px}}@media(max-width: 256px){.style__circle___QVwOK{margin-left:20px;margin-top:-35px}}@keyframes style__spin___3k0z_{to{border-top-color:#fff;-webkit-transform:rotate(360deg);transform:rotate(360deg)}}", ""]);

// Exports
exports.locals = {
	"countdown": "style__countdown___1Ow7-",
	"countdown-number": "style__countdown-number___1vi4T",
	"countdownNumber": "style__countdown-number___1vi4T",
	"circle": "style__circle___QVwOK",
	"spin": "style__spin___3k0z_",
	"countdown-anim": "style__countdown-anim___3k8i6",
	"countdownAnim": "style__countdown-anim___3k8i6"
};