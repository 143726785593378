exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".style__express-recorder__recorder___34Q4Y,\n.style__express-recorder__recorder__no-video___3cm9C {\n  height: 100%;\n  width: 100%;\n  border-radius: 4px;\n  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#777777+0,828c95+0,434343+100 */\n  background: #777777;\n  /* Old browsers */\n  background: -moz-radial-gradient(center, ellipse cover, #777777 0%, #828c95 0%, #434343 100%);\n  /* FF3.6-15 */\n  background: -webkit-radial-gradient(center, ellipse cover, #777777 0%, #828c95 0%, #434343 100%);\n  /* Chrome10-25,Safari5.1-6 */\n  background: radial-gradient(ellipse at center, #777777 0%, #828c95 0%, #434343 100%);\n  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */\n  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#777777', endColorstr='#434343',GradientType=1 );\n  /* IE6-9 fallback on horizontal gradient */ }\n\n.style__video-object-wrap___37k_G {\n  width: 100%;\n  height: 100%; }\n\n.style__no-video-text___2mDH- {\n  position: absolute;\n  margin-top: 25px;\n  margin-left: 18px;\n  color: white; }\n\n.style__audio-indicator___16P-6 {\n  float: right;\n  position: relative;\n  margin-top: 8px;\n  margin-left: 10px; }\n\n.style__express-recorder__recorder__share-screen___1iea1 {\n  height: auto;\n  width: 30%;\n  border-radius: 4px;\n  overflow: hidden;\n  z-index: 10;\n  position: absolute;\n  right: 0;\n  bottom: 0; }\n\n.style__express-recorder__screen___2_fo9 {\n  position: absolute;\n  left: 0;\n  height: inherit;\n  width: inherit; }\n", ""]);

// Exports
exports.locals = {
	"express-recorder__recorder": "style__express-recorder__recorder___34Q4Y",
	"expressRecorderRecorder": "style__express-recorder__recorder___34Q4Y",
	"express-recorder__recorder__no-video": "style__express-recorder__recorder__no-video___3cm9C",
	"expressRecorderRecorderNoVideo": "style__express-recorder__recorder__no-video___3cm9C",
	"video-object-wrap": "style__video-object-wrap___37k_G",
	"videoObjectWrap": "style__video-object-wrap___37k_G",
	"no-video-text": "style__no-video-text___2mDH-",
	"noVideoText": "style__no-video-text___2mDH-",
	"audio-indicator": "style__audio-indicator___16P-6",
	"audioIndicator": "style__audio-indicator___16P-6",
	"express-recorder__recorder__share-screen": "style__express-recorder__recorder__share-screen___1iea1",
	"expressRecorderRecorderShareScreen": "style__express-recorder__recorder__share-screen___1iea1",
	"express-recorder__screen": "style__express-recorder__screen___2_fo9",
	"expressRecorderScreen": "style__express-recorder__screen___2_fo9"
};