exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".style__countdown___1Ow7- {\n  position: relative;\n  margin: auto;\n  margin-top: 100px;\n  height: 88px;\n  width: 88px;\n  text-align: center; }\n\n.style__countdown-number___1vi4T {\n  color: white;\n  display: inline-block;\n  line-height: 88px;\n  font-size: 48px;\n  z-index: 2;\n  position: relative; }\n\n@keyframes style__countdown-anim___3k8i6 {\n  from {\n    stroke-dashoffset: 0; }\n  to {\n    stroke-dashoffset: 264px; } }\n\n.style__circle___QVwOK {\n  -webkit-animation: style__spin___3k0z_ 1s linear infinite;\n  animation: style__spin___3k0z_ 1s linear infinite;\n  border: 4px solid rgba(0, 0, 0, 0);\n  border-top: 4px solid white;\n  border-radius: 50%;\n  height: 75px;\n  width: 75px;\n  margin-top: -86px;\n  margin-left: 2px;\n  background-color: rgba(0, 0, 0, 0.5); }\n\n@keyframes style__spin___3k0z_ {\n  to {\n    border-top-color: white;\n    -webkit-transform: rotate(360deg);\n    transform: rotate(360deg); } }\n", ""]);

// Exports
exports.locals = {
	"countdown": "style__countdown___1Ow7-",
	"countdown-number": "style__countdown-number___1vi4T",
	"countdownNumber": "style__countdown-number___1vi4T",
	"circle": "style__circle___QVwOK",
	"spin": "style__spin___3k0z_",
	"countdown-anim": "style__countdown-anim___3k8i6",
	"countdownAnim": "style__countdown-anim___3k8i6"
};