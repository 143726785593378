exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".audioWave__audio-wave-wrap___27GcD{height:10px}.audioWave__audio-wave-wrap___27GcD .audioWave__wave___-jlWH{position:absolute;width:4px;height:0;background-color:#fff;bottom:0}@keyframes audioWave__sequence1___3wg25{0%{height:1px}50%{height:16px}100%{height:1px}}@keyframes audioWave__sequence2___10uMt{0%{height:3px}50%{height:10px}100%{height:3px}}.audioWave__wave___-jlWH:nth-child(1){left:0;animation:audioWave__sequence2___10uMt 1.5s ease infinite 0s}.audioWave__wave___-jlWH:nth-child(2){left:5px;animation:audioWave__sequence1___3wg25 1.5s ease infinite .1s}.audioWave__wave___-jlWH:nth-child(3){left:10px;animation:audioWave__sequence1___3wg25 1.5s ease-in-out infinite .2s}.audioWave__wave___-jlWH:nth-child(4){left:15px;animation:audioWave__sequence2___10uMt 1.5s ease-in infinite .3s}.audioWave__audio-wave-inactive___1f2Rw .audioWave__wave___-jlWH{animation:none;height:1px}", ""]);

// Exports
exports.locals = {
	"audio-wave-wrap": "audioWave__audio-wave-wrap___27GcD",
	"audioWaveWrap": "audioWave__audio-wave-wrap___27GcD",
	"wave": "audioWave__wave___-jlWH",
	"sequence2": "audioWave__sequence2___10uMt",
	"sequence1": "audioWave__sequence1___3wg25",
	"audio-wave-inactive": "audioWave__audio-wave-inactive___1f2Rw",
	"audioWaveInactive": "audioWave__audio-wave-inactive___1f2Rw"
};