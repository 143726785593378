exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".audioWave__audio-wave-wrap___27GcD {\n  height: 10px; }\n  .audioWave__audio-wave-wrap___27GcD .audioWave__wave___-jlWH {\n    position: absolute;\n    width: 4px;\n    height: 0;\n    background-color: #fff;\n    bottom: 0; }\n\n@keyframes audioWave__sequence1___3wg25 {\n  0% {\n    height: 1px; }\n  50% {\n    height: 16px; }\n  100% {\n    height: 1px; } }\n\n@keyframes audioWave__sequence2___10uMt {\n  0% {\n    height: 3px; }\n  50% {\n    height: 10px; }\n  100% {\n    height: 3px; } }\n\n.audioWave__wave___-jlWH:nth-child(1) {\n  left: 0;\n  animation: audioWave__sequence2___10uMt 1.5s ease infinite 0s; }\n\n.audioWave__wave___-jlWH:nth-child(2) {\n  left: 5px;\n  animation: audioWave__sequence1___3wg25 1.5s ease infinite 0.1s; }\n\n.audioWave__wave___-jlWH:nth-child(3) {\n  left: 10px;\n  animation: audioWave__sequence1___3wg25 1.5s ease-in-out infinite 0.2s; }\n\n.audioWave__wave___-jlWH:nth-child(4) {\n  left: 15px;\n  animation: audioWave__sequence2___10uMt 1.5s ease-in infinite 0.3s; }\n\n.audioWave__audio-wave-inactive___1f2Rw .audioWave__wave___-jlWH {\n  animation: none;\n  height: 1px; }\n", ""]);

// Exports
exports.locals = {
	"audio-wave-wrap": "audioWave__audio-wave-wrap___27GcD",
	"audioWaveWrap": "audioWave__audio-wave-wrap___27GcD",
	"wave": "audioWave__wave___-jlWH",
	"sequence2": "audioWave__sequence2___10uMt",
	"sequence1": "audioWave__sequence1___3wg25",
	"audio-wave-inactive": "audioWave__audio-wave-inactive___1f2Rw",
	"audioWaveInactive": "audioWave__audio-wave-inactive___1f2Rw"
};