exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".style__player-wrap___3YNS9,.style__player-wrap-screen___1ppCI{height:100%;width:100%}.style__players-wrap___-cns2{display:flex;height:100%;width:100%}", ""]);

// Exports
exports.locals = {
	"player-wrap": "style__player-wrap___3YNS9",
	"playerWrap": "style__player-wrap___3YNS9",
	"player-wrap-screen": "style__player-wrap-screen___1ppCI",
	"playerWrapScreen": "style__player-wrap-screen___1ppCI",
	"players-wrap": "style__players-wrap___-cns2",
	"playersWrap": "style__players-wrap___-cns2"
};