exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "button.style__timer-button___3FQGj{cursor:pointer;background-color:rgba(0,0,0,0);border:rgba(0,0,0,0);padding:0}button.style__timer-button___3FQGj svg{vertical-align:text-top}.style__recording-menu___3ZZaC{padding:13px 22px 13px 14px;height:30px;border-radius:28px;background-color:#222;color:#fff;font-size:16px;font-weight:700;display:flex;align-items:center;justify-content:space-between;width:82px}@media(max-width: 512px){.style__recording-menu___3ZZaC{height:15px}}@media(max-width: 320px){.style__recording-menu___3ZZaC{height:8px;padding:13px 10px 13px 10px}}.style__recording-timer___1gDMY{display:inline-block}.style__timer___10btU{margin-left:8px}", ""]);

// Exports
exports.locals = {
	"timer-button": "style__timer-button___3FQGj",
	"timerButton": "style__timer-button___3FQGj",
	"recording-menu": "style__recording-menu___3ZZaC",
	"recordingMenu": "style__recording-menu___3ZZaC",
	"recording-timer": "style__recording-timer___1gDMY",
	"recordingTimer": "style__recording-timer___1gDMY",
	"timer": "style__timer___10btU"
};