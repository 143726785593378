exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".style__error-screen-wrap___3Isvv {\n  height: 100%;\n  width: 100%;\n  background-color: #ffdd87;\n  color: #8b572a;\n  text-shadow: 1px 1px 0 0 rgba(255, 255, 255, 0.5);\n  margin: auto;\n  display: flex;\n  align-items: center; }\n  .style__error-screen-wrap___3Isvv .style__error-screen___2dXD3 {\n    text-align: center;\n    width: 100%; }\n    .style__error-screen-wrap___3Isvv .style__error-screen___2dXD3 h1.style__error-screen__title___15S7s {\n      font-size: 18px;\n      font-weight: bold;\n      line-height: 22px; }\n    .style__error-screen-wrap___3Isvv .style__error-screen___2dXD3 p.style__error-screen__text___BcQ5s {\n      font-size: 16px;\n      line-height: 19px;\n      width: 75%;\n      margin: auto;\n      overflow: auto;\n      word-wrap: break-word; }\n\n.style__error-screen__reset-button___2eg4o {\n  height: 36px;\n  width: 130px;\n  position: relative;\n  font-size: 14px;\n  font-weight: 500;\n  outline: none;\n  cursor: pointer;\n  margin-top: 20px; }\n", ""]);

// Exports
exports.locals = {
	"error-screen-wrap": "style__error-screen-wrap___3Isvv",
	"errorScreenWrap": "style__error-screen-wrap___3Isvv",
	"error-screen": "style__error-screen___2dXD3",
	"errorScreen": "style__error-screen___2dXD3",
	"error-screen__title": "style__error-screen__title___15S7s",
	"errorScreenTitle": "style__error-screen__title___15S7s",
	"error-screen__text": "style__error-screen__text___BcQ5s",
	"errorScreenText": "style__error-screen__text___BcQ5s",
	"error-screen__reset-button": "style__error-screen__reset-button___2eg4o",
	"errorScreenResetButton": "style__error-screen__reset-button___2eg4o"
};