exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".style__express-recorder__recorder___34Q4Y,.style__express-recorder__recorder__no-video___3cm9C{height:100%;width:100%;border-radius:4px;background:#777;background:-moz-radial-gradient(center, ellipse cover, rgb(119, 119, 119) 0%, rgb(130, 140, 149) 0%, rgb(67, 67, 67) 100%);background:-webkit-radial-gradient(center, ellipse cover, rgb(119, 119, 119) 0%, rgb(130, 140, 149) 0%, rgb(67, 67, 67) 100%);background:radial-gradient(ellipse at center, rgb(119, 119, 119) 0%, rgb(130, 140, 149) 0%, rgb(67, 67, 67) 100%);filter:progid:DXImageTransform.Microsoft.gradient( startColorstr=\"#777777\", endColorstr=\"#434343\",GradientType=1 )}.style__video-object-wrap___37k_G{width:100%;height:100%}.style__no-video-text___2mDH-{position:absolute;margin-top:25px;margin-left:18px;color:#fff}.style__audio-indicator___16P-6{float:right;position:relative;margin-top:8px;margin-left:10px}.style__express-recorder__recorder__share-screen___1iea1{height:auto;width:30%;border-radius:4px;overflow:hidden;z-index:10;position:absolute;right:0;bottom:0}.style__express-recorder__screen___2_fo9{position:absolute;left:0;height:inherit;width:inherit}", ""]);

// Exports
exports.locals = {
	"express-recorder__recorder": "style__express-recorder__recorder___34Q4Y",
	"expressRecorderRecorder": "style__express-recorder__recorder___34Q4Y",
	"express-recorder__recorder__no-video": "style__express-recorder__recorder__no-video___3cm9C",
	"expressRecorderRecorderNoVideo": "style__express-recorder__recorder__no-video___3cm9C",
	"video-object-wrap": "style__video-object-wrap___37k_G",
	"videoObjectWrap": "style__video-object-wrap___37k_G",
	"no-video-text": "style__no-video-text___2mDH-",
	"noVideoText": "style__no-video-text___2mDH-",
	"audio-indicator": "style__audio-indicator___16P-6",
	"audioIndicator": "style__audio-indicator___16P-6",
	"express-recorder__recorder__share-screen": "style__express-recorder__recorder__share-screen___1iea1",
	"expressRecorderRecorderShareScreen": "style__express-recorder__recorder__share-screen___1iea1",
	"express-recorder__screen": "style__express-recorder__screen___2_fo9",
	"expressRecorderScreen": "style__express-recorder__screen___2_fo9"
};